<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageEditionAtelier"
  >
    <base-material-card icon="mdi-account-outline">
      <v-row>
        <v-col>
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ atelier.nom }}
              <span class="body-1">— {{ $t('edition.atelier') }}</span>
            </div>
          </template>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="atelier.nom"
                  data-cy="tfNomAtelier"
                  :label="$t('general.nom')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="atelier.identifiant"
                  data-cy="tfIdentifiantAtelier"
                  :label="$t('general.identifiant')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :label="$t('general.debut')"
                  :value.sync="atelier.dateDebut"
                  data-cy="dtfDateDebutAtelier"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <cnx-date-time
                  :label="$t('general.fin')"
                  data-cy="dtfDateFinAtelier"
                  :value.sync="atelier.dateFin"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
      >
        <v-col>
          <v-btn
            data-cy="btnSauvegarderAtelier"
            color="primary"
            class="mr-0"
            @click="onSauvegarderAtelier()"
          >
            {{ $t('general.sauvegarder') }}
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            data-cy="btnLancerRedirectionAtelier"
            class="secondary"
            @click="onRedirectionAtelier()"
          >
            {{ $t('atelier.lancer_la_redirection') }}
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
    <atelier-place
      :atelier="atelier"
      data-cy="apAtelier"
    />
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import CnxDateTime from '../../composantes/CnxDateTime.vue'
  import AtelierPlace from './AtelierPlace'
  import i18n from '@/i18n.js'

  export default {
    components: {
      CnxDateTime,
      AtelierPlace,
    },
    data: () => ({
      atelier: {},
      loading: true,
    }),
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        restApiService
          .get(`/api/ateliers/${this.$route.params.idAtelier}`)
          .then(result => {
            this.atelier = result.data
          })
          .catch(erreur => alert(erreur))
      },
      onRedirectionAtelier () {
        restApiService
          .get('/api/ateliers/redirectionAtelier/' + this.atelier.id)
          .then(result => {
            this.ateliers = result.data
            // this.count = parseInt(result.headers['x-total-count'])
            this.loading = false
          })
          .catch(erreur => {
            alert(erreur)
            this.loading = false
          })
      },
      onSauvegarderAtelier () {
        restApiService
          .put('/api/ateliers/', this.atelier)
          .then(result => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
            this.getData()
          })
          .catch(erreur => alert(erreur))
      },
    },
  }
</script>

<style>

</style>
