<template>
  <base-material-card icon="mdi-badge-account-horizontal-outline">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t('atelier.places') }}
      </div>
    </template>
    <v-text-field
      v-model="mots"
      append-icon="mdi-magnify"
      class="ml-auto"
      :label="$t('general.chercher')"
      hide-details
      single-line
      style="max-width: 250px;"
    />

    <v-divider class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="places"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: $t('general.lignes_par_page'),
        itemsPerPageAllText: $t('tout'),
        pageText: `{0}-{1} ${$t('general.de')} {2}`
      }"
      :search.sync="search"
      data-cy="dtAtelierPlaces"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="px-2 ml-1 warning"
          min-width="0"
          small
          @click="onEffacerAtelierPlace(item)"
        >
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <selection-usager
      v-if="peutAjouter"
      :libelle="$t('atelier.creer_une_nouvelle_place')"
      @usager-selection="onUsagerIdentifié($event)"
    />
  </base-material-card>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import SelectionUsager from './../../composantes/SelectionUsager'
  import i18n from '@/i18n.js'

  export default {
    components: {
      SelectionUsager,
    },
    props: {
      atelier: Object,
    },
    data: () => ({
      loading: true,
      peutAjouter: true,
      count: 0,
      search: undefined,
      mots: '',
      places: [],
      options: {},
      headers: [
        { text: i18n.t('general.prenom'), value: 'usager.prenom' },
        { text: i18n.t('general.nom'), value: 'usager.nom' },
        { text: i18n.t('general.courriel'), value: 'usager.courriel' },
        { text: i18n.t('general.actions'), value: 'actions' },
      ],
    }),

    watch: {
      atelier: {
        handler(newValue) {
          if (newValue !== undefined) {
            this.getData()
          }
        },
      },
      mots: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      getData() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}`
        restApiService
          .get(`/api/ateliers/${this.atelier.id}/places?${arg}&mots=${this.mots}`)
          .then(result => {
            this.places = result.data
            this.count = parseInt(result.headers['x-total-count'])
            this.peutAjouter = this.count < 20
            this.loading = false
          })
          .catch(erreur => {
            alert(erreur)
            this.loading = false
          })
      },
      onEffacerAtelierPlace(place) {
        restApiService
          .delete('/api/atelier-places/' + place.id)
          .then(result => {
            this.getData()
          })
          .catch(erreur => {
            alert(erreur)
          })
      },
      onUsagerIdentifié(u) {
        const info = {
          idUsager: u.id,
        }
        restApiService
          .post(`/api/ateliers/${this.atelier.id}/places`, info)
          .then(result => {
            this.getData()
          })
          .catch(erreur => {
            alert(erreur)
          })
      },
    },
  }
</script>
